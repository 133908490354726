import styles from "../styles/Footer.module.css"

import Address from "../components/playbook/Address"
import Button from "../components/playbook/Button"
import Body from "../components/playbook/Body"
import Flex from "../components/playbook/Flex"
import FlexItem from "../components/playbook/FlexItem"

type FooterProps = {
  domain: string
}

const Footer = (props: FooterProps) => (
  <footer>
    <Flex
        align={"start"}
        className={styles.hero}
        padding={"lg"}
        wrap
    >
      <FlexItem paddingX={"lg"}>
        <img
            src={props.domain == "streaminnovations" ? "/images/stream-innovations-logo-dark-bg.png" : "/images/stream-financial-logo.png"}
            className={styles.footerLogo}
            width={"120"}
        />
      </FlexItem>
      <FlexItem grow>
        <Flex
            align={"stretch"}
            justify={"between"}
        >
          <Flex
            align={"stretch"}
            className={styles.contactInfo}
            orientation={"column"}
            gap={"sm"}
            paddingX={"lg"}
          >
            <Body
                className={styles.contactTitle}
                text={"Contact Us"}
            />
            <Body text={"610-922-1070"} />
            <Body text={`customerservice@${props.domain}.com`} />
            <Address
                address={"PO Box 460"}
                city={"Newtown Square"}
                state={"PA"}
                zipcode={"19073-0460"}
            />
          </Flex>

          <Flex
            align={"stretch"}
            className={styles.contactInfo}
            orientation={"column"}
            paddingX={"lg"}
          >
            <Body
                className={styles.contactTitle}
                paddingBottom={"sm"}
                text={"Customer Support Hours"}
            />
            <Body text={"Monday - Thursday: 8am - 7pm"} />
            <Body text={"Friday: 8am - 5pm"} />
          </Flex>

          <Flex orientation={"column"}>
            <Body
                dark
                paddingX={"lg"}
                text={"Disclosures"}
            />
            <a
                href={"/docs/esign.pdf"}
                target={"_blank"}
                rel={"noopener noreferrer"}
            >
              <Button
                  className={styles.docLink}
                  paddingX={"lg"}
                  text={"E Sign"}
                  variant={"link"}
              />
            </a>
            <a
                href={"/docs/credit_authorization.pdf"}
                target={"_blank"}
                rel={"noopener noreferrer"}
            >
              <Button
                  className={styles.docLink}
                  paddingX={"lg"}
                  text={"Credit Authorization"}
                  variant={"link"}
              />
            </a>
            <a
                href={"/docs/privacy.pdf"}
                target={"_blank"}
                rel={"noopener noreferrer"}
            >
              <Button
                  className={styles.docLink}
                  paddingX={"lg"}
                  variant={"link"}
                  text={"Privacy Statement"}
              />
            </a>
            <a
                href={"/docs/regulatory_information"}
                target={"_blank"}
                rel={"noopener noreferrer"}
            >
              <Button
                  className={styles.docLink}
                  paddingX={"lg"}
                  variant={"link"}
                  text={"Regulatory Information"}
              />
            </a>
          </Flex>
        </Flex>
      </FlexItem>
    </Flex>
  </footer>
)

export default Footer
